@import 'bulma/css/bulma.css';

.main {
  min-height: 70vh;
  display: flex;
}

.text-red {
  color: red;
  font-weight: 600;
}

.cursor-pointer {
  cursor: pointer;
}
