.loginWindow {
  height: 100vh;
  width: 100vw;

  display: flex;
  justify-content: center;
  align-items: center;
}
.boxCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}
.width-max {
  width: max-content;
}
